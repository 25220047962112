import ajax from '@/utils/ajax.js'


/////////////////////     第三方订单对账     ////////////////////

/**
 * 下单总览
 * @param {*} params 
 * @returns 
 */
export function orderOverview(params) {
  return ajax.post('/OtherPlatformBill/orderOverview',params)
}

/**
 * 发货 总览
 * @param {*} params 
 * @returns 
 */
export function dlvrOverview(params) {
  return ajax.post('/OtherPlatformBill/deliverOverview',params)
}

/**
 * 退款 总览
 * @param {*} params 
 * @returns 
 */
export function refundOverview(params) {
  return ajax.post('/OtherPlatformBill/refundOverview',params)
}

/**
 * 发货未回款 总览
 * @param {*} params 
 * @returns 
 */
export function deliverNotReturnedMoneyOverview(params) {
  return ajax.post('/OtherPlatformBill/deliverNotReturnedMoneyOverview',params)
}

/**
 * 订单列表 
 * @param {*} params 
 * @returns 
 */
export function orderList(params) {
  return ajax.post('/OtherPlatformBill/getOrderList',params)
}

/**
 * 订单详情
 * @param {*} params 
 * @returns 
 */
export function orderDetail(params) {
  return ajax.post('/OtherPlatformBill/getOrderInfo',params)
}

/**
 * 运单列表 
 * @param {*} params 
 * @returns 
 */
export function waybillList(params) {
  return ajax.post('/OtherPlatformBill/getWaybillList',params)
}

/**
 * 导出 运单列表 
 * @param {*} params 
 * @returns 
 */
export function exportWaybillList(params) {
  return ajax.openurl('/OtherPlatformBillExport/Waybill',params)
}

/**
 * 退款列表 
 * @param {*} params 
 * @returns 
 */
export function refundList(params) {
  return ajax.post('/OtherPlatformBill/getRefundFlowList',params)
}
